export default [
  {
    title: 'Dashboards',
    route: 'home',
    icon: 'HomeIcon',
    tag: '3',
    tagVariant: 'danger',
    resource: 'STATS',
    action: 'view',
    children: [
      {
        title: 'Summary',
        route: 'home',
        icon: 'CircleIcon',
        resource: 'STATS',
        action: 'view',
      },
      {
        title: 'Data',
        route: 'home',
        icon: 'DownloadCloudIcon',
        tag: 'Filter/Export',
        tagVariant: 'light-warning',
        resource: 'STATS',
        action: 'view',
      },
      {
        title: 'Analytics',
        route: 'apps-stats-graphs',
        icon: 'BarChartIcon',
        resource: 'STATS',
        action: 'view',
      },
    ],
  },
  {
    title: 'Payments',
    icon: 'DollarSignIcon',
    resource: 'PAYMENTS',
    action: 'view',
    children: [
      {
        title: 'Deposits',
        route: 'apps-payins-list',
        icon: 'CircleIcon',
        resource: 'PAYINS',
        action: 'view',
      },
      {
        title: 'Withdrawals',
        route: 'apps-payouts-list',
        icon: 'CircleIcon',
        resource: 'PAYOUTS',
        action: 'view',
      },
    ],
  },
  {
    title: 'Players',
    route: 'apps-players-list',
    icon: 'UsersIcon',
    resource: 'PLAYERS',
    action: 'view',
  },
  {
    title: 'Bets by Provider',
    route: 'apps-bets-list',
    icon: 'ListIcon',
    resource: 'BETS',
    action: 'view',
    children: [
      {
        title: 'Gammastack',
        route: 'apps-bets-list',
        icon: 'GridIcon',
        resource: 'GAMES',
        action: 'view',
      },
      // {
      //   title: 'Onlyplay',
      //   route: 'misc-coming-soon',
      //   icon: 'GridIcon',
      //   resource: 'GAMES',
      //   action: 'view',
      // },
      // {
      //   title: 'Aviator',
      //   route: 'misc-coming-soon',
      //   icon: 'GridIcon',
      //   resource: 'GAMES',
      //   action: 'view',
      // }
    ]
  },
  {
    title: 'Manage',
    icon: 'SettingsIcon',
    resource: 'MANAGE',
    action: 'view',
    children: [
      {
        title: 'Games',
        route: 'apps-games-list',
        icon: 'GridIcon',
        resource: 'GAMES',
        action: 'view',
      },
      {
        title: 'Team',
        route: 'apps-users-list',
        icon: 'UsersIcon',
        resource: 'TEAM',
        action: 'view',
      },
    ],
  },
]
